<!-- 人力資源 > 樂活金寶 -->
<template>
    <section :data-sec-scroll="L2.Title" :class="['nkg__section bg-happy-section', L2.Styles, { 'lang-en': this.$route.params.lang === 'en-US'}]">
        <img class="img-fluid mb-5 d-block d-lg-none" src="https://www.kinpo.com.tw/kpo_file/bg_page_careers_section_lohas_top.png" />
        <div class="container-lg">
            <h2 v-if="L2.Title!==''" class="h3 text-center fw-bold" v-html="L2.Title"></h2>
            <div :id="L2.ID" v-html="L2.ContentUnderTitle" class="mt-3 mb-5"></div>
            <div class="nkg__wrap">
                <div class="nkg__tab__wrap position-relative" style="min-height:170px;">
                    <div v-for="(t, i) in circleTabDisplay" :key="i" class="w-100" :class="{'position-absolute': deviceMode !== 'mobile'}">
                        <transition :name="carouselName">
                            <ul v-show="nowCircleTab == i" class="nav row justify-content-between">
                                <!-- <li v-for="(tt, ii) in circleTabDisplay[i]" :key="ii"
                                class="col-md-4"
                                :class="{'mb-5': ii+1 < circleTabDisplay[i].length, 'w-100': deviceMode == 'mobile'}"
                                @mouseover.passive="hover(ii)" @click="hover(ii)"> -->
                                <li v-for="(tt, ii) in circleTabDisplay[i]" :key="ii"
                                class="col-md-4"
                                :class="{'mb-5': ii+1 < circleTabDisplay[i].length, 'w-100': deviceMode == 'mobile'}">
                                    <div
                                        class="nkg__tab nkg__block--circle mx-auto active"
                                        :style="{ backgroundImage: 'url(\'' + tt.Img + '\')' }"
                                    >
                                        <div class="h4 fw-bold mb-0" v-html="tt.Title"></div>
                                    </div>
                                    <div>
                                        <div class="nkg__tab__content">
                                            <div class="nkg__tab__inner">
                                                <div class="nkg__list">
                                                    <div v-html="tt.SubTitle"></div>
                                                </div>                                              
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </transition>
                    </div>
                    <!-- <div v-if="deviceMode !== 'mobile'" class="arrows">
                        <svg-icon v-if="!(nowCircleTab == 0 && nowHover == 0)"
                            class="splide__arrow splide__arrow--prev"
                            name="arrow-l"
                            size="40"
                            @click.prevent="toPrevItem"
                        />
                        <svg-icon v-if="!(nowCircleTab + 1 == circleTabDisplay.length && nowHover + 1 == circleTabDisplay[nowCircleTab].length)"
                            class="splide__arrow splide__arrow--next"
                            name="arrow-r"
                            size="40"
                            @click.prevent="toNextItem"
                        />
                    </div> -->
                </div>
                <!-- <template v-if="deviceMode !== 'mobile'">
                    <div v-for="(box, i) in circleTabDisplay[nowCircleTab]" :key="i">
                        <transition name="fadeInUp">
                            <div class="nkg__tab__content" v-if="nowHover == i">
                                <div class="nkg__tab__inner">
                                    <div class="nkg__list">
                                        <div v-html="box.SubTitle"></div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </template> -->
            </div>
        </div>
        <img class="img-fluid d-block d-lg-none" src="https://www.kinpo.com.tw/kpo_file/bg_page_careers_section_lohas_bottom.png" />
    </section>
</template>

<script>
import $ from "jquery";
import { inject } from 'vue';

export default {
    props: {
        pageindex: [Number, String],
        L2: Object
    },    
    data: function() {
        return {
            nowHover: 0,			
			circleTabDisplay: [],
			carouselName: 'carousel-next',
			nowCircleTab: 0,
            perGroupLength: 3,
        };
    },
    setup() {
        const deviceMode = inject('deviceMode');
        return {
            deviceMode,
        }
    },
    mounted() {
        let allItem_W = (170 + 32) * this.L2.Data.length;
        let wrap_w = $(".nkg__container").width();
		if(allItem_W < wrap_w || this.deviceMode == 'mobile'){
            this.perGroupLength = this.L2.Data.length;			
		}
		this.circleTabDisplay = _.chunk(this.L2.Data, this.perGroupLength);
    },
    methods: {
        hover(i) {
            this.nowHover = i;
        },
        toNextItem(){
            if(this.nowHover < this.perGroupLength - 1){
                this.nowHover++;
            } else {
                this.nowHover = 0;
                this.toNextPage();
            }
        },
        toPrevItem(){
            if(this.nowHover > 0){
                this.nowHover--;
            } else {
                this.nowHover = this.perGroupLength - 1;
                this.toPrevPage();
            }
        },
		toNextPage() {
			this.carouselName = 'carousel-next';
            this.nowCircleTab + 1 >= this.L2.Data.length ? this.nowCircleTab = 0 : this.nowCircleTab = this.nowCircleTab + 1;
		},
		toPrevPage() {
			this.carouselName = 'carousel-prev';
            this.nowCircleTab - 1 < 0 ? this.nowCircleTab = this.L2.Data.length - 1 : this.nowCircleTab = this.nowCircleTab - 1;
		},
    },
};
</script>

<style lang="scss" scoped>
.bg-happy-section {
    background: url("https://www.kinpo.com.tw/kpo_file/bg_kinpo_happy_life.png") no-repeat, #d9d9d9;
    background-size: 98%;
    background-position: center;
    min-height: 750px;
}

@media (max-width:1920px) {
    .bg-happy-section {
        background-size: 82%;
    }
}
@media (max-width:1440px) {
    .bg-happy-section {
        background-size: 98%;
        min-height: 720px;
    }
    .lang-en.bg-happy-section {
        min-height: 820px;
    }
}
@media (max-width:1200px) {
    .bg-happy-section {
        background-size: 0;
    }
}
@media (max-width:1024px) {
    .bg-happy-section {
        background:  #d9d9d9;
    }
}
.nkg {
    // tab & tab-content
    &__tab {
        position: relative;
        @include bgFillCenter;
        background-size: auto 170px;
        border: 0 solid transparent;
        color: #fff;
        font-weight: bold;
        font-size: 1.125rem;
        transition: $sec ease-in-out;
        border: 5px solid #fff;         
        &.active {
            border: 5px solid #fff;
            &::before {
                background-color: rgba($blue-700, .2);
            }
            &::after {
                transform: translateX(-50%) rotate(45deg);   
                opacity: 1; 
                transition: $sec ease-in-out;
            }
        }
        .h4 {
            position: relative;
            z-index: 2;
        }
        &__wrap{
            overflow-x: clip;
            overflow-y: visible;
        }
        &__content {
            position: relative;
            margin-top: 2.5rem;
            border-radius: $border-radius * 2;
            box-shadow: 0 1px 2px 6px rgba(0, 0, 0, 0.03);
        }
        &__inner {
            position: relative;
            padding: 2rem 1.2rem;
            background-color: #fff;
            border-radius: $border-radius * 2;
            min-height: 190px;
            z-index: 2;
            @include media-breakpoint-down(sm) {
                padding: 2rem 1.5rem;
            }
        }
    }
}
.nkg__block--circle {
    width: 260px;
    height: 260px;
    background-size: cover;
}
.nkg__tab__content {
    margin-top: 2rem;
    text-align: justify;
    position: relative;
}

.nkg__tab__content::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 9rem;
    left: 50%;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 0.3125rem;
    box-shadow: 0 1px 2px 6px rgba(0, 0, 0, 0.03);
    transform-origin: center;
    transform: translate(-50%, 1rem) rotate(45deg);
    opacity: 1;
    z-index: 1;
}
.nkg__tab .h4 {
    position: absolute;
    top: -14px;
    right: -12px;
    z-index: 2;
    border-radius: 50%;
    background: #134A8F;
    width: 105px;
    height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav {
    width: 88%;
    margin: auto;
    flex-wrap: inherit;
}
.arrows {
    display: none;
}
.lang-en .nkg__tab .h4 {
    font-size: 16px;
    width: 115px;
    height: 115px;
    border: 6px solid #134a8f;
}
@media(min-width:1440px) {
    .nkg__tab__content::after {
        bottom: 9rem;
    }
    .nkg__tab__inner {
        min-height: 155px!important;
    }
    .lang-en .nkg__tab__content::after {
        bottom: 16.4rem;
    }
    .lang-en .nkg__tab__inner {
        min-height: 274px!important;
    }
}
@media(min-width:1500px) {
    .nkg__tab__content::after {
        bottom: 11rem;
    }
    .nkg__tab__inner {
        min-height: 185px!important;
    }
}
@media(min-width:1920px) {
    .nkg__tab__content::after {
        bottom: 9rem;
    }
    .nkg__tab__inner {
        min-height: 155px!important;
    }
}
@media(max-width:1440px) {
    .nkg__tab__content::after {
        bottom: 11rem;
    }
    .nkg__tab__inner {
        min-height: 190px!important;
    }

    .lang-en .nkg__tab__content::after {
        bottom: 16.4rem;
    }

    .lang-en .nkg__tab__inner {
        min-height: 275px!important;
    }
}
@media(max-width:1200px) {
    .lang-en .nkg__tab__content::after {
        bottom: 19rem;
    }

    .lang-en .nkg__tab__inner {
        min-height: 315px!important;
    }
}
@media (max-width: 1130px) {
    .nkg__block--circle {
        width: 250px;
        height: 250px;
    }
    .nkg__tab__content {
        margin-top: 2rem;
    }
}
@media (max-width: 1062px) {
    .nkg__block--circle {
        width: 230px;
        height: 230px;
    }
    .nkg__tab__content {
        margin-top: 2rem;
    }
}
@media(max-width:1024px) {
    .nkg__tab__content::after {
        bottom: 12.5rem;
    }
    .nkg__tab__inner {
        min-height: 214px!important;
    }
}
@media (max-width: 993px) {
    .nkg__block--circle {
        width: 200px;
        height: 200px;
    }
    .nkg__tab__content {
        margin-top: 2rem;
    }
    .lang-en .nkg__tab__content::after {
        bottom: 20rem;
    }

    .lang-en .nkg__tab__inner {
        min-height: 335px!important;
    }
}
@media (max-width: 890px) {
    .nkg__block--circle {
        width: 180px;
        height: 180px;
    }
    .nkg__tab__content {
        margin-top: 3rem;
    }
    .lang-en .nkg__tab__content::after {
        bottom: 26rem;
    }

    .lang-en .nkg__tab__inner {
        min-height: 425px!important;
    }
}
@media (max-width: 822px) {
    .nkg__block--circle {
        width: 170px;
        height: 170px;
    }
    .nkg__tab__content {
        margin-top: 2rem;
    }
}
@media(max-width:820px) {
    .bg-happy-section {
        padding: 0;
    }
    .nkg__wrap {
        min-height: 500px;
    }
    .nkg__tab .h4 {
        right: -30px;
    }
    .nkg__block--circle {
        width: 200px;
        height: 200px;
    }
    .nkg__tab__content::after {
        bottom: 14.5rem;
    }
    .nkg__tab__inner {
        min-height: 245px!important;
    }
}
@media (max-width: 768px) {
    .nkg__block--circle {
        width: 170px;
        height: 170px;
    }
    .nkg__tab__content {
        margin-top: 2rem;
    }
    .nkg__tab__content::after {
        bottom: 14.5rem;
    }
    .nkg__tab__inner {
        min-height: 245px!important;
    }
    .lang-en .nkg__tab__content::after {
        bottom: 13rem;
    }

    .lang-en .nkg__tab__inner {
        min-height: 215px!important;
    }
    .nkg__tab .h4 {
        width: 95px;
        height: 95px;
        font-size: 18px;
        right: -38px;
    }
    .nav {
        flex-wrap: wrap;
    }
}
@media (max-width: 576px) {
    .bg-happy-section {
        padding: 0;
    }
    .nkg__block--circle {
        width: 250px;
        height: 250px;
    }
    .nkg__tab__content {
        margin-top: 30px;
    }
    .nkg__tab__content::after {
        bottom: 11.5rem;
    }
    .nkg__tab__inner {
        min-height: 190px!important;
    }
    .lang-en .nkg__tab__content::after {
        bottom: 16.4rem;
    }
    .lang-en .nkg__tab__inner {
        min-height: 275px!important;
    }
    .nkg__tab .h4 {
        width: 115px;
        height: 115px;
        font-size: 22px;
    }
}
@media (max-width: 375px) {
    .nkg__tab__content::after {
        bottom: 11rem;
    }
    .nkg__tab__inner {
        min-height: 185px!important;
    }
    .lang-en .nkg__tab__content::after {
        bottom: 18.4rem;
    }
    .lang-en .nkg__tab__inner {
        min-height: 305px!important;
    }
}
</style>